import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo } from "react";

import AddToCartBundle from "../AddToCartBundle";
import { HorizontalProductCard } from "./HorisontalProductCard";
import { brandsToExclude } from "../../queries/toExclude";
import useAuth from "../useAuth";

const OneProductBundle = ({
  crossSellProducts,
  products,
  mainProductId,
  mainProductPrice,
  isCart,
}: any) => {
  const { loggedIn, isUnlicensedUser, isCustomerDefault } = useAuth();
  const constantId = mainProductId;

  const getProductById = (productSku: string) =>
    products?.find((p: { sku: string }) => p.sku === productSku);

  const getTotalPrice = (bundleProducts: any) => {
    const numericMainPrice = mainProductPrice
      ? parseFloat(mainProductPrice.toString().replace(/[^0-9.-]+/g, ""))
      : 0;

    return bundleProducts.reduce(
      (total: number, product: { productSku: string }) => {
        const matchingProduct = getProductById(product.productSku);
        if (matchingProduct && matchingProduct.price) {
          const numericPrice = parseFloat(
            matchingProduct.price.replace(/[^0-9.-]+/g, "")
          );
          return total + numericPrice;
        }
        return total;
      },
      numericMainPrice
    );
  };

  const ProductImages = ({ bundleProducts }: any) => (
    <HStack gap="4px">
      {bundleProducts.map((product: { productSku: string }) => {
        const matchingProduct = getProductById(product.productSku);
        if (matchingProduct) {
          return (
            <Image
              key={product.productSku}
              w="40px"
              h="40px"
              borderRadius="8px"
              border="1px solid #DCA672"
              src={matchingProduct.image?.sourceUrl}
            />
          );
        }
        return null;
      })}
    </HStack>
  );

  const ProductNames = ({ bundleProducts }: any) => (
    <Text fontSize="12px" textAlign="left">
      {bundleProducts.map((product: { productSku: string }, index: number) => {
        const matchingProduct = getProductById(product.productSku);
        if (matchingProduct) {
          return (
            <React.Fragment key={product.productSku}>
              {index > 0 && ", "}
              {matchingProduct.name}
            </React.Fragment>
          );
        }
        return null;
      })}
    </Text>
  );

  const ProductIds = (bundleProducts: any) => {
    const ids = bundleProducts
      .map((product: { productSku: string }) => {
        const matchingProduct = getProductById(product.productSku);
        return matchingProduct ? matchingProduct.databaseId : null;
      })
      .filter((id: number) => id !== null);

    return isCart ? ids : [constantId, ...ids];
  };

  const isBundleRestricted = (bundleProducts: any) => {
    return bundleProducts.every((product: { productSku: string }) => {
      const matchingProduct = getProductById(product.productSku);
      return matchingProduct
        ? matchingProduct.productTags?.brandName.every((i: any) =>
            brandsToExclude.includes(i.name)
          )
        : false;
    });
  };

  return crossSellProducts.map((bundle: any) => {
    const totalPrice = useMemo(
      () => getTotalPrice(bundle.bundleProducts),
      [bundle.bundleProducts, mainProductPrice]
    );

    const productIds = ProductIds(bundle.bundleProducts);

    if (
      isBundleRestricted(bundle.bundleProducts) &&
      (!loggedIn || isCustomerDefault || isUnlicensedUser)
    ) {
      return null;
    }

    const cartName =
      bundle.bundleProducts.length > 1 ? "Add this bundle" : "Add to cart";

    return (
      <Box
        w="100%"
        bg="#FBF5F0"
        borderRadius="16px"
        p="10px"
        mb="8px"
        key={bundle.bundleName}>
        <Stack
          flexDirection={{ base: "column", sm: "row" }}
          justifyContent="space-between">
          <VStack w="100%" alignItems={{ base: "center", sm: "flex-start" }}>
            {bundle.bundleProducts.map((product: { productSku: string }) => {
              const matchingProduct = getProductById(product.productSku);

              if (matchingProduct) {
                return (
                  <HorizontalProductCard
                    key={product.productSku}
                    props={matchingProduct}
                    outlet={false}
                    margin=".3rem"
                    width="236px"
                    isProductBundles={true}
                  />
                );
              }
              return null;
            })}
          </VStack>
          <VStack>
            <Text>${totalPrice.toFixed(2)} total</Text>

            <AddToCartBundle
              productId={productIds}
              name={bundle.bundleName}
              price={totalPrice.toFixed(2)}
              cartName={cartName}
            />
          </VStack>
        </Stack>
      </Box>
    );
  });
};

export default OneProductBundle;
