// import { Button, Link, Text, useToast } from '@chakra-ui/react';
// import { gql, useMutation } from '@apollo/client';

// import { FaPlus } from 'react-icons/fa6';
// import React from 'react';
// import { useAppState } from './context';

// const ADD_TO_CART = gql`
// 	mutation ATC($input: AddToCartInput!) {
// 		addToCart(input: $input) {
// 			cart {
// 				subtotal
// 				total
// 				shippingTotal
// 				contents {
// 					itemCount
// 					nodes {
// 						quantity
// 						product {
// 							node {
// 								name
// 								sku
// 								databaseId
// 								... on SimpleProduct {
// 									price
// 									regularPrice
// 									salePrice
// 									upsell {
// 										upsellProducts: nodes {
// 											name
// 											slug
// 											databaseId
// 											description
// 											image {
// 												sourceUrl
// 											}
// 											productTags {
// 												nodes {
// 													name
// 												}
// 											}
// 											... on SimpleProduct {
// 												id
// 												name
// 												price
// 												regularPrice
// 												onSale
// 												stockStatus
// 											}
// 										}
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// `;

// const AddToCartBundle: React.FC<{
// 	productId: number[];
// 	name: String;
// 	price: String;
// }> = ({ productId, name, price }) => {
// 	const toast = useToast();
// 	const { setCart } = useAppState();
// 	const [addToCart, { data, loading, error }] = useMutation(ADD_TO_CART, {
// 		onCompleted: ({ addToCart }) => {
// 			toast({
// 				title: (
// 					<Text>
// 						Added to&nbsp;
// 						<Link
// 							href='/cart'
// 							fontWeight={700}
// 							textDecoration='underline'>
// 							cart
// 						</Link>
// 					</Text>
// 				),
// 				status: 'success',
// 			});
// 			setCart(addToCart.cart);

// 			// @ts-ignore
// 			window.dataLayer = window.dataLayer || [];
// 			// @ts-ignore
// 			window.dataLayer.push({
// 				event: 'add_to_cart',
// 				ecommerce: {
// 					items: [
// 						{
// 							item_name: name,
// 							item_id: productId,
// 							price: price ? parseFloat(price.replace('$', '')) : undefined,
// 							quantity: '1',
// 						},
// 					],
// 				},
// 			});
// 		},
// 		onError: () => {
// 			toast({
// 				title: 'Error',
// 				description: 'There was an error adding your product',
// 				status: 'error',
// 			});
// 		},
// 	});

// 	function handleAddToCartBundle() {
// 		productId.forEach((id) => {
// 			addToCart({
// 				variables: { input: { productId: id, quantity: 1 } },
// 			});
// 		});
// 	}

// 	return (
// 		<div>
// 			<Button
// 				onClick={() => handleAddToCartBundle()}
// 				isLoading={loading}
// 				loadingText='Adding to cart'
// 				variant='solidBlack'
// 				bg='#000'
// 				color='#fff'
// 				border='1px solid black'
// 				textTransform='uppercase'
// 				height='40px'
// 				fontSize='12px'
// 				fontWeight='700'
// 				borderRadius='40px'
// 				gap='8px'
// 				_hover={{
// 					color: '#000',
// 					bg: '#fff',
// 					border: '1px solid #120B0C',
// 				}}>
// 				<FaPlus />
// 				Add this bundle
// 			</Button>
// 		</div>
// 	);
// };

// export default AddToCartBundle;

import { Button, Link, Text, useToast } from "@chakra-ui/react";
import { gql, useMutation } from "@apollo/client";

import { FaPlus } from "react-icons/fa6";
import React from "react";
import { useAppState } from "./context";

const ADD_TO_CART = gql`
  mutation ATC($input: AddToCartInput!) {
    addToCart(input: $input) {
      cart {
        subtotal
        total
        shippingTotal
        contents {
          itemCount
          nodes {
            quantity
            product {
              node {
                name
                sku
                databaseId
                id
                name
                slug
                databaseId
                image {
                  sourceUrl
                  altText
                }
                crossSellProducts {
                  bundles {
                    bundleName
                    bundleProducts {
                      productSku
                    }
                  }
                }
                productTags {
                  nodes {
                    name
                  }
                }
                productCategories {
                  nodes {
                    name
                  }
                }
                ... on SimpleProduct {
                  price
                  regularPrice
                  salePrice
                  upsell {
                    upsellProducts: nodes {
                      name
                      slug
                      databaseId
                      description
                      image {
                        sourceUrl
                      }
                      productTags {
                        nodes {
                          name
                        }
                      }
                      ... on SimpleProduct {
                        id
                        name
                        price
                        regularPrice
                        onSale
                        stockStatus
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const AddToCartBundle: React.FC<{
  productId: number[];
  name: String;
  price: String;
  cartName?: any;
}> = ({ productId, name, price, cartName }) => {
  const toast = useToast();
  const { setCart } = useAppState();
  const [addToCart, { loading, error }] = useMutation(ADD_TO_CART, {
    onCompleted: ({ addToCart }) => {
      setCart(addToCart.cart);
    },
    onError: () => {
      toast({
        title: "Error",
        description: "There was an error adding your product",
        status: "error",
      });
    },
  });

  const handleAddToCartBundle = async () => {
    try {
      for (const id of productId) {
        await addToCart({
          variables: { input: { productId: id, quantity: 1 } },
        });
      }

      toast({
        title: (
          <Text>
            Added to&nbsp;
            <Link href="/cart" fontWeight={700} textDecoration="underline">
              cart
            </Link>
          </Text>
        ),
        status: "success",
      });

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: productId.map((id) => ({
            item_name: name,
            item_id: id,
            price: price ? parseFloat(price.replace("$", "")) : undefined,
            quantity: 1,
          })),
        },
      });
    } catch (e) {
      console.error(e);
      toast({
        title: "Error",
        description: "There was an error adding your bundle",
        status: "error",
      });
    }
  };

  return (
    <div>
      <Button
        onClick={() => handleAddToCartBundle()}
        isLoading={loading}
        loadingText="Adding to cart"
        variant="solidBlack"
        bg="#000"
        color="#fff"
        border="1px solid black"
        textTransform="uppercase"
        height="40px"
        fontSize="12px"
        fontWeight="700"
        borderRadius="40px"
        gap="8px"
        _hover={{
          color: "#000",
          bg: "#fff",
          border: "1px solid #120B0C",
        }}
      >
        <FaPlus />
        {cartName}
      </Button>
    </div>
  );
};

export default AddToCartBundle;
